<template>
  <v-app-bar color="transparent" dense flat elevation="0">
    <v-breadcrumbs
      divider="/"
      :items="breadcrumbs"
      class="ml-n6 breadcrumb-item"
    ></v-breadcrumbs>
    <v-spacer />
    <v-chip>
      <v-avatar
        color="primary"
        class="text-uppercase white--text mx-1 ml-n2 breadcrumb-item"
      >
        {{ `${userData.email[0]}${userData.email[1]}` }}
      </v-avatar>
      {{ userData.employee }}
    </v-chip>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppraisalBreadcrumbs",
  computed: {
    breadcrumbs() {
      const matched = this.$route.matched.filter((el) => el.name);
      matched.unshift({ path: "/appraisal/dashboard", name: "Dashboard" });
      return matched.map((el) => {
        return {
          text: el.name,
          disabled: el.name === this.$route.name,
          href: el.path,
        };
      });
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
  },
};
</script>

<style scoped></style>
