<template>
  <v-container>
    <AppraisalBreadcrumbs />
    <router-view name="view" />
    <router-view name="action" />
  </v-container>
</template>

<script>
import AppraisalBreadcrumbs from "../../appraisalv3/components/AppraisalBreadcrumbs";
export default {
  name: "Appraisal",
  components: { AppraisalBreadcrumbs },
};
</script>

<style scoped></style>
